import _ from 'lodash'
import { rest } from '../utils/rest'
import {
    GET_ERRORS,
    GET_FACULTY_BUSSINESS_TYPE,
    GET_PROVINCES,
    GET_COMPANY_TYPE,
    GET_FACULTY_DEGREES,
    GET_CATEGORY
} from './types';


export const getBusinessTypes = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('faculty', {params:{
                action:'listBussinessTypeSelect'
            }})
            .then(res=>{
                dispatch({
                    type:GET_FACULTY_BUSSINESS_TYPE,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

export const getCompanyTypes = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('company_type', {params:{
                action:'listCompanyTypeSelect'
            }})
            .then(res=>{
                dispatch({
                    type:GET_COMPANY_TYPE,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}


export const getProvinces = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('state', {params:{
                action:'getPropinsi',
                negara_id:'1'
            }})
            .then(res=>{
                dispatch({
                    type:GET_PROVINCES,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

export const getCities = (code) => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('state', { params: {
                action:'getKabupaten',
                lokasi_kode:code
            }})
            .then(res=>{
                resolve(res.data)
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

export const getDegrees = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('degree', {params:{
                action:'listAcademicdegreeSelect',
            }})
            .then(res=>{
                dispatch({
                    type:GET_FACULTY_DEGREES,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

export const getFaculties = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('faculty', {params:{
                action:'listBussinessTypeSelect',
            }})
            .then(res=>{
                dispatch({
                    type:GET_FACULTY_BUSSINESS_TYPE,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

export const getCategory = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('category', {params:{
                action:'listCategorySelect',
            }})
            .then(res=>{
                dispatch({
                    type:GET_CATEGORY,
                    payload:res.data
                })
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}


// listAcademicdegreeSelect