import axios from 'axios'
import { cacheStore } from "./helpers"
import config from "./config"

const getApiUrl = () => {
    return process.env.GATSBY_API_URL
}

export const restDropzone = (endpoint, body=null) => {
    return {
        url:getApiUrl() + endpoint,
        headers:{
            'Authorization':'Bearer ' + cacheStore().getItem(config.tokenKey),
        },
        body,
        method:'post'
    }
}

export const rest = () => {
    axios.defaults.baseURL = getApiUrl()
    axios.defaults.headers.post['Content-Type'] = 'application/json'

    if (cacheStore().getItem(config.tokenKey)) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + cacheStore().getItem(config.tokenKey)
    }

    return axios
}

export const restForm = (bodyFormData) => {
    return axios.create({
        baseURL: getApiUrl(),
        // `headers` are custom headers to be sent
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + cacheStore().getItem(config.tokenKey)
        }
    })
}