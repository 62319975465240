import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAIwf0AZkHt8UdV3C49sOLAg4jjlteONLA",
    authDomain: "jobsmigas-user-notification.firebaseapp.com",
    databaseURL: "https://jobsmigas-user-notification.firebaseio.com",
    projectId: "jobsmigas-user-notification",
    storageBucket: "jobsmigas-user-notification.appspot.com",
    messagingSenderId: "191282035696",
    appId: "1:191282035696:web:3f0ffaeeecbca6af073b35"
});

const messaging = initializedFirebaseApp.messaging();

messaging.usePublicVapidKey(
	// Project Settings => Cloud Messaging => Web Push certificates
  "BL5OS67oatcv9mJO4YaRJOz6cO2eV6Oe-y40qMRK6JmlHnOtHFd1OAR4Fzt6YaKjFeWZxRbdcNZBqTbaSnuIElg"
);
export { messaging };