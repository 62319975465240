import { 
    GET_ERRORS,
    GET_MESSAGE_BOXES,
    GET_MESSAGE_CHATS,
    GET_MESSAGE_BOX,
    POST_MESSAGE,
    FETCHING_NEW_MESSAGE,
    FETCHED_NEW_MESSAGE,
    NEW_MESSAGE_BOX
} from '../actions/types';

const initialState = {
    box:null,
    list: null,
    chats: null,
    loading: false,
    fetching:false,
    new_chats:null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MESSAGE_BOXES:
            return {
                ...state,
                list: action.payload
            };
        case GET_MESSAGE_BOX:
            return {
                ...state,
                box: action.payload
            };
        case GET_MESSAGE_CHATS:
            return {
                ...state,
                chats: action.payload,
            }
        case FETCHING_NEW_MESSAGE:
            return {
                ...state,
                fetching: true
            }
        case FETCHED_NEW_MESSAGE:
            return {
                ...state,
                fetching: false
            }
        case NEW_MESSAGE_BOX:
            return {
                ...state,
                new_chats: action.payload
            }
        default:
            return state;
    }
}