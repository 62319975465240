import _ from 'lodash'
import { rest } from '../utils/rest'
import config from '../utils/config'
import { getCompanyTypes, getDegrees, getFaculties, getProvinces } from "./category"
import {
    cacheStore,
    encrypt,
} from '../utils/helpers'
import {
    GET_PROFILE,
    GET_ERRORS,
    SET_CURRENT_USER,
    CLEAR_CURRENT_PROFILE,
    REGISTER_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VERIFY_FORGOT_PASSWORD,
    RESEND_VERIFICATION
} from './types';

// Login - Get User Token
export const loginUser = userData => dispatch => {
    return new Promise((resolve, reject) => {
        let decoded;
        rest()
            .post('auth/signin', userData)
            .then(res => {
                // Save to localStorage
                const {
                    token,
                    user
                } = res.data;
                // Set token to ls
                cacheStore().setItem(config.tokenKey, token)

                // set user object to storage
                // set required user field to Storage
                setUserToStorage(user)

                // Set current user
                dispatch(setCurrentUser(user))

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};


export const pushFirebaseToken = (args) => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post(`tokens`, args)
            .then(res=>{
                resolve(res.data)
            })
            .catch(err=>{
                reject(err)
            })
    })
}

// Login - Get User Token
export const resendVerification = id => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get(`auth/resend/${id}`)
            .then(res => {
                // Set current user
                dispatch({
                    type:RESEND_VERIFICATION
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });
};

// Login - Get User Token
export const registerUser = userData => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('auth/register', userData)
            .then(res => {
                // Set current user
                dispatch({
                    type:REGISTER_USER
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });
};


// Login - Get User Token
export const forgotPassword = userData => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('auth/forgot.request', userData)
            .then(res => {
                // Set current user
                dispatch({
                    type:FORGOT_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};


// Login - Get User Token
export const verifyForgotPassword = code => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('auth/forgot.verify', {
                params:{
                    code:code
                }
            })
            .then(res => {
                // Set current user
                dispatch({
                    type:VERIFY_FORGOT_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};


// Login - Get User Token
export const resetPassword = args => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .post('auth/reset', args)
            .then(res => {
                // Set current user
                dispatch({
                    type:RESET_PASSWORD
                })

                resolve(res.data)
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
                reject(err)
            })
            .then(function () {
            })
    });

};

export const getCollectionOfUser = () => dispatch => {
    let promises = []
    promises.push(dispatch(getUser()))
    promises.push(dispatch(getDegrees()))
    promises.push(dispatch(getCompanyTypes()))
    promises.push(dispatch(getFaculties()))
    promises.push(dispatch(getProvinces()))
    Promise.all(promises)
}

export const getUser = () => dispatch => {
    return new Promise((resolve, reject) => {
        rest()
            .get('me')
            .then(res=>{
                
                // set required user field to Storage
                setUserToStorage(res.data)

                // dispatch redux
                dispatch({
                    type:GET_PROFILE,
                    payload:res.data
                })

                resolve(res.data)
            })
            .catch(err=>{
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            })
    })
}

// Set logged in user
export const setCurrentUser = (decoded = {}) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

// Clear profile
export const clearCurrentProfile = () => {
    return {
        type: CLEAR_CURRENT_PROFILE
    };
}

// Log user out
export const logoutUser = () => dispatch => {
    rest()
        .get('me/logout')
        .then(res => {
            // Remove token from sessionStorage
            cacheStore().removeItem(config.tokenKey);
            // Remove token from sessionStorage
            cacheStore().removeItem(config.userObjKey);
            // Set current user to {} which will set isAuthenticated to false
            dispatch(setCurrentUser());
        })
        .catch(err => {
            if( err.response.status == 401) {
                // Remove token from sessionStorage
                cacheStore().removeItem(config.tokenKey);
                // Remove token from sessionStorage
                cacheStore().removeItem(config.userObjKey);
                // Set current user to {} which will set isAuthenticated to false
                dispatch(setCurrentUser());
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : err
                })
            }
           
        })
        .then(function () {
        })
};

export const setUserToStorage = (obj, updateObj={}) => {
    const { 
        id,
        title,
        jobseekers_id,
        jobseekers_date,
        jobseekers_marital,
        jobseekers_firstname,
        jobseekers_lastname,
        jobseekers_image,
        jobseekers_resume,
        jobseekers_provinsi,
        jobseekers_city,
        jobseekers_address,
        jobseekers_gender,
        jobseekers_phone,
        experinceList,
        educations,
        languanges,
        computer,
        last_login,
        log_web_version,
        email,
        about 
    } = obj

    let d
    if( ! _.isEmpty(updateObj) ) {
        d = _.merge({
            id,
            title,
            jobseekers_id,
            jobseekers_date,
            jobseekers_marital,
            jobseekers_firstname,
            jobseekers_lastname,
            jobseekers_provinsi,
            jobseekers_city,
            jobseekers_address,
            jobseekers_gender,
            jobseekers_image,
            jobseekers_resume,
            jobseekers_phone,
            experinceList,
            educations,
            languanges,
            computer,
            last_login,
            log_web_version,
            email,
            about
        }, updateObj)
    } else {
        d = {
            id,
            title,
            jobseekers_id,
            jobseekers_date,
            jobseekers_marital,
            jobseekers_firstname,
            jobseekers_lastname,
            jobseekers_image,
            jobseekers_resume,
            jobseekers_provinsi,
            jobseekers_city,
            jobseekers_address,
            jobseekers_gender,
            jobseekers_phone,
            experinceList,
            educations,
            languanges,
            computer,
            last_login,
            log_web_version,
            email,
            about 
        }
    }
    
    // set user object to storage
    cacheStore().setItem(config.userObjKey, encrypt(d))

    return d
}