import _ from 'lodash'
import { rest } from '../utils/rest'
import { isEmpty, indexOf, pullAll } from "lodash"
import {
    cacheStore,
    encrypt
} from '../utils/helpers'
import {
    GET_ERRORS,
    GET_MESSAGE_BOXES,
    GET_MESSAGE_BOX,
    GET_MESSAGE_CHATS,
    POST_MESSAGE,
    NEW_MESSAGE_BOX
} from './types';


export const fetchMessageBoxes = (args={}) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .get('messages', {params:args})
           .then(res=>{
               dispatch({
                  type:GET_MESSAGE_BOXES,
                  payload:res.data
               })

               if( ! isEmpty(res.data.list) ) {
                let cbox = []
                res.data.list.map((item, id)=>{
                  if( item.last_chat.user_id == null ) {
                    cbox.push(item.id)
                  }
                })
                dispatch({
                  type:NEW_MESSAGE_BOX,
                  payload:cbox
                })
              }

               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err.response)
           })
   })
}

export const fetchMessageBox = (box_id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rest()
        .get("/messages/" + box_id)
        .then(res => {
          dispatch({
            type: GET_MESSAGE_BOX
          })
        
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    });
}
  
export const fetchMessageChats = msg_id => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      rest()
        .get("/messages/" + msg_id + "/chats")
        .then(res => {
          dispatch({
            type: GET_MESSAGE_CHATS,
            payload: res.data
          })

          const { message } = getState()
            console.log(getState())
            let b = message.new_chats
            if( indexOf(b, msg_id) > -1 ) {
              pullAll(b, [msg_id])
              dispatch({
                type:NEW_MESSAGE_BOX,
                payload:b
              })
            }

          // save to temporary open message box
          setStorageMessageChats(msg_id)

          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
};
  
export const postMessageChats = (msg_id, args) => dispatch => {
    return new Promise((resolve, reject) => {
      rest()
        .post("/messages/" + msg_id + "/chats", args)
        .then(res => {
          dispatch({
            type: POST_MESSAGE
          });
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
};

const setStorageMessageChats = (msg_id) => {
  cacheStore('session').setItem("_jki_mboxOpen", encrypt([msg_id]))
}
  
