/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { UserCurrentProvider } from "./src/context/CurrentUserContext"
import { cacheStore, decryptObject, isMobile, createNotification } from "./src/utils/helpers"
import { has, indexOf, isEmpty } from "lodash"
import { rest } from "./src/utils/rest"
import config from "./src/utils/config"
import store from './src/utils/store'
import { setCurrentUser, pushFirebaseToken } from "./src/actions/auth"
import { fetchMessageChats } from "./src/actions/message"
import { detect } from "detect-browser"
import { Provider } from 'react-redux'
import { messaging } from "./src/init-fcm"
import { FETCHING_NEW_MESSAGE, FETCHED_NEW_MESSAGE, NEW_MESSAGE_BOX } from "./src/actions/types"
import { NotificationManager } from 'react-notifications';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This website has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const wrapRootElement = ({ element }) => (
  <UserCurrentProvider>
    <Provider store={store}>
      {element}
    </Provider>
  </UserCurrentProvider>
)

const insertJS = () => {
    const s = document.createElement(`script`)
    s.type = `text/javascript`
    s.src = process.env.GATSBY_MID_CLIENT_URL
    s.setAttribute("data-client-key", process.env.GATSBY_MID_CLIENT_KEY);

    document.getElementsByTagName(`head`)[0].appendChild(s)
}

const firebaseRequestPerm = async () => {
  messaging.requestPermission()
    .then(async function() {
			const token = await messaging.getToken().then((currentToken)=>{
        if(currentToken) {
          sendTokenToServer(currentToken)
          window.localStorage.setItem('sentToServer', '1');
        } else {
          window.localStorage.setItem('sentToServer', '0');
        }

      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        window.localStorage.setItem('sentToServer', '0');
      })

    })
    .catch(function(err) {
      console.log("Unable to get permission to notify.", err);
    })
}

const firebaseRefreshToken = async () => {
  // [START refresh_token]
  // Callback fired if Instance ID token is updated.
  messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
      console.log('Token refreshed.');
      // Indicate that the new Instance ID token has not yet been sent to the
      // app server.
      window.localStorage.setItem('sentToServer', '1');
      // Send Instance ID token to app server.
      sendTokenToServer(refreshedToken);
      
    }).catch((err) => {
      console.log('Unable to retrieve refreshed token ', err);
      window.localStorage.setItem('sentToServer', '0');
    });
  });
}

const messageListener = () => {
  navigator.serviceWorker.addEventListener("message", (message) => {
    const data = message.data.firebaseMessagingData.data
    const mboxOpen = cacheStore('session').getItem('_jki_mboxOpen')

    NotificationManager.info(message.data.firebaseMessagingData.notification.body, 'Info!', 10000)

    if( mboxOpen ) {
      const msgDecrypted = decryptObject(mboxOpen)
      if( parseInt(data.msg_id) == msgDecrypted[0] ) {
        store.dispatch({
          type:FETCHING_NEW_MESSAGE
        })
        store.dispatch(fetchMessageChats(data.msg_id)).then(res=>{
          const { message } = store.getState()
          let b = message.new_chats
          if( indexOf(b, data.msg_id) === -1) {
            b.push(data.msg_id)
            store.dispatch({
              type:NEW_MESSAGE_BOX,
              payload:b
            })
          }

          store.dispatch({
            type:FETCHED_NEW_MESSAGE
          })
        })
      }
    }
  });
}

const isTokenSentToServer = () => {
  return window.localStorage.getItem('sentToServer') === '1'
}
// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
const sendTokenToServer = (currentToken) => {
  if (!isTokenSentToServer()) {
    console.log('Sending token to server...');
    const browser = detect()
    // Decode token and get user info and exp
    const userEnc = cacheStore().getItem(config.userObjKey);
    const userObj = decryptObject(userEnc)

    store.dispatch(pushFirebaseToken({
      token:currentToken,
      os:isMobile() ? browser.os : 'Desktop/' + browser.os,
      device_no:browser.version
    }))
    
  } else {
    console.log('Token already sent to server so won\'t send it again ' +
        'unless it changes');
  }

}

const registerFCMWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("http://localhost:8002/sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
}

export const onInitialClientRender = () => {
  // add midtrans js
  insertJS()

  if (cacheStore().getItem(config.tokenKey) && cacheStore().getItem(config.userObjKey) ) {
    // firebaseRequestPerm()
    messageListener()
  }
}

export const onServiceWorkerInstalled = () => {
  //alert('installed')
}

export const onPreRouteUpdate = ({location}) => {
  if (cacheStore().getItem(config.tokenKey) && cacheStore().getItem(config.userObjKey) ) {
      // call firebase
      firebaseRequestPerm()

      // refresh token
      firebaseRefreshToken()

      // Decode token and get user info and exp
      const payload = cacheStore().getItem(config.tokenKey)

      // Decode token and get user info and exp
      const userEnc = cacheStore().getItem(config.userObjKey)
      const userObj = decryptObject(userEnc)

      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(userObj));
  } 
}   