// Profile
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const REGISTER_USER = 'REGISTER_USER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const VERIFY_FORGOT_PASSWORD = 'VERIFY_FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const PP_IMAGE_UPLOADED = 'PP_IMAGE_UPLOADED'
export const RESUME_UPLOADED = 'RESUME_UPLOADED'
export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';

export const ADD_EXPERIENCE = 'ADD_EXPERIENCE'
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE'
export const ADD_EDUCATION = 'ADD_EDUCATION'
export const DELETE_EDUCATION = 'DELETE_EDUCATION'
export const ADD_SKILL = 'ADD_SKILL'
export const DELETE_SKILL = 'DELETE_SKILL'
export const ADD_LANGUAGE = 'ADD_LANGUAGE'
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE'
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const UPLOAD_CV = 'UPLOAD_CV'
export const UPLOAD_CERT = 'UPLOAD_CERT'

// Loader
export const LOADER_START = 'LOADING_SPINNER_START'
export const LOADER_COMPLETE = 'LOADING_SPINNER_COMPLETE'
export const LOADER_ERROR = 'LOADING_SPINNER_ERROR'
export const FORM_LOADING_START = 'FORM_LOADING_START'
export const FORM_LOADING_FINISH = 'FORM_LOADING_FINISH'

// Modal
export const MODAL_SHOW = "Modal/SHOW"
export const MODAL_HIDE = "Modal/HIDE"
export const MODAL_BUTTON_LOADING_START = "Modal/BUTTON_LOADING"
export const MODAL_BUTTON_LOADING_END = "Modal/BUTTON_FINISH"

// Categories
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_FACULTY_BUSSINESS_TYPE = "GET_FACULTY_BUSSINESS_TYPE"
export const GET_COMPANY_TYPE = "GET_COMPANY_TYPE"
export const GET_PROVINCES = "GET_PROVINCES"
export const GET_CITIES = "GET_CITIES"
export const GET_FACULTY_DEGREES = "GET_FACULTY_DEGREES"

// Applications
export const GET_APPLICATIONS = "GET_APPLICATIONS"
export const SAVE_JOB_INTERESTING = "SAVE_JOB_INTERESTING"


// Exceptions
export const GET_ERRORS = 'GET_ERRORS';

// Payment
export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const SUCCESS_PAYMENT = 'SUCCESS_PAYMENT'
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'

// Message Box
export const GET_MESSAGE_BOXES = 'GET_MESSAGE_BOXES';
export const GET_MESSAGE_CHATS = 'GET_MESSAGE_CHATS';
export const GET_MESSAGE_BOX = 'GET_MESSAGE_BOX';
export const NEW_MESSAGE_BOX = 'NEW_MESSAGE_BOX'
export const POST_MESSAGE = 'POST_MESSAGE';
export const FETCHING_NEW_MESSAGE = 'FETCHING_NEW_MESSAGE'
export const FETCHED_NEW_MESSAGE = 'FETCHED_NEW_MESSAGE'